import React from 'react'
import { IRootUserCompany } from '../Studio/services/UserService'
import { EAccessType } from '../AppContext'
import { User } from '../models/User'
import Http from '../Http/Http'
import { CacheHandler } from '../Http/CacheHandler'
import Cookies from 'js-cookie'
import SessionHandler from '../Http/SessionHandler'

// Remarque:
// On va migrer ces conditions dans le SessionHandler dans le prochain lot-8

// FAKE CONTEXT FOR MODE
// Nous avons centralisé ici la gestion des données utilisées pour le switch
// Si jamais nous devrions modifier le mode de stockage, c'est ici qu'on modifiera

export const isModeExpert = (user?: User): boolean => {
    return user?.permissions?.expert === true
}

export const setVeriflixLogin = (params: URLSearchParams): URLSearchParams => {
    params.set('source', 'veriflix')
    return params
}

export const isVeriflixLogin = (params: URLSearchParams) => {
    return params.get('source') === 'veriflix'
}

// L'utilisateur a accès à veriflix
export const hasVeriflixAccess = (user?: User): boolean => {
    return isVeriflixUser(user) || hasVeriflixPermission(user)
}

// Utilisateur veriflix ?
export const isVeriflixUser = (user?: User): boolean => {
    return user?.role?.id === 'veriflix' || SessionHandler.isVeriflixUser()
}
// Utilisateur audit-tag
export const isAuditTagUser = (user?: User): boolean => {
    return user?.permissions?.auditTag === true
}
// Utilisateur audit-Hb
export const isAuditHbUser = (user?: User): boolean => {
    return user?.permissions?.auditHb === true
}

// Possède une permission à veriflix ?
export const hasVeriflixPermission = (user?: User): boolean => {
    return user?.permissions?.veriflix_media_platform === true
}

export const isRoot = (user?: User): boolean => {
    return Boolean(user?.accessType === EAccessType.ROOT)
}

// Utilisateur consumption
export const isConsumptionUser = (user?: User): boolean => {
    return user?.permissions?.showConsumption === true
}

export const isHasRoleAdops = (user?: User): boolean => {
    // ticket IN-60: C'est la vraie condition lorsque decide de mettre en prod le lot-9
    // return isRoot(user) || user?.permissions?.Adops === true
    return isSocietyMode(user) || (user?.accessType === EAccessType.USER && user?.role?.id === 'adops')
}

export const isHasRoleAdminAdops = (user?: User): boolean => {
    return isSocietyMode(user) || (user?.accessType === EAccessType.USER && user?.role?.id === 'admin_adops')
}

export const isHasRoleWrapperDigiteka = (user?: User): boolean =>
    isSocietyMode(user) || (isUserMode(user) && user?.permissions?.Adops === true)

export const isGloablModeForRouteSwitching = (): boolean => Boolean(getSocietyId() === '0')

export const isGlobalMode = (user?: User): Boolean => {
    return Boolean(getSocietyId() === '0' && user?.accessType === EAccessType.ROOT)
}

export const isSocietyMode = (user?: User): boolean => {
    return Boolean(getSocietyId() !== '0' && getSocietyId() !== null && user?.accessType === EAccessType.ROOT)
}

export const isUserMode = (user?: User): boolean => {
    return Boolean(user?.accessType === EAccessType.USER)
}

/**
    MODE GLOBAL et MODE SOCIETE
    Cette propriétée statique est utile pour connaître si on est on mode GLOBAL ou SOCIÉTÉ dans les fonctions qui n'ont pas accès au state
    On peut imaginer aussi d'utiliser un "cookie" ou le "localeStorage"
    
    Pour la valeur on va mettre:
    null => user
    0 => mode global
    :id_societe => mode société (:id_societe est l'id de la société)
*/
const SOCIETY_ID = 'society_id'

interface ISocietySession {
    id: string
    data?: IRootUserCompany
}

export const resetDigitekaSession = () => {
    Cookies.remove(SOCIETY_ID)
}

export const getSociety = (): IRootUserCompany | undefined => {
    let session = Cookies.get(SOCIETY_ID)
    return session ? (JSON.parse(session) as ISocietySession).data : undefined
}

export const getSocietyId = (): string | null => {
    let session = Cookies.get(SOCIETY_ID)
    return session ? (JSON.parse(session) as ISocietySession).id : null
}

export const setSocietyId = (data: ISocietySession) => {
    // Peuvent-être encore améliorer
    const cacheHandler = new CacheHandler('')
    cacheHandler.deleteItem('studio/contexts')
    Cookies.set(SOCIETY_ID, JSON.stringify(data))
}

export const setToGlobalMode = () => {
    Http.defaultTimeout = 120000
    setSocietyId({ id: '0' })
}

export const canSetToModeGlobalOnFirstEnter = () => getSocietyId() === null || getSocietyId() === '0'

export function useModeManagement(): IModeManagement {
    const [currentSociety, setCurrentSociety] = React.useState<IRootUserCompany | undefined>(undefined)

    const backModeManagementToZero = React.useCallback(() => {
        setCurrentSociety(undefined)
        Cookies.remove(SOCIETY_ID)
    }, [])

    return {
        currentSociety,
        setCurrentSociety,
        backModeManagementToZero
    }
}

export interface IModeManagement {
    currentSociety: IRootUserCompany | undefined
    setCurrentSociety: React.Dispatch<React.SetStateAction<IRootUserCompany | undefined>>
    backModeManagementToZero: () => void
}

export const ModeManagementContext = React.createContext<IModeManagement | any>({})

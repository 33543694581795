import moment from 'moment'
import slugify from 'slugify'

export const getNbPage = (totalItems: number, itemsPerPage: number): number => Math.ceil(totalItems / itemsPerPage)
export const generateTemporaryId = (): string => `${Date.now()}`

/**
 * Kilo octet
 */
export const Ko = 2 ** 10

/**
 * Mega octect
 */
export const Mo = Ko * 2 ** 10

export const noAction = () => {
    // silence is golden
}

export const sortArrayObj = (obj: any[], property: string) => {
    let newObj = obj.sort((a: any, b: any): number => {
        if (a[property] < b[property]) return -1
        if (a[property] > b[property]) return 1
        return 0
    })
    return newObj
}

export const blobToFile = (theBlob: Blob, fileName: string): File => {
    return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type })
}

export const threeDayMs = 3 * 24 * 60 * 60 * 1000

export function formatVideoDuration(durationSeconds = 0) {
    if (durationSeconds >= 3600) return moment.utc(moment.duration(durationSeconds, 'seconds').asMilliseconds()).format('HH:mm:ss')
    return moment.utc(moment.duration(durationSeconds, 'seconds').asMilliseconds()).format('mm:ss')
}

export function digitekaSlugify(s: string) {
    // Ce lib ne gère pas les ',"
    return slugify(s).replace(/['"]/g, '-')
}

export function pureSlugify(s: string) {
    return s.toLowerCase().replace(/[^a-z0-9]/g, '-')
}

export function readJSON<T>(file: File): Promise<T> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()

        reader.onload = e => {
            if (!reader.result) return
            try {
                const content = JSON.parse(reader.result as string)
                resolve(content)
            } catch (e) {
                reject('Invalid JSON')
            }
        }

        reader.onerror = error => {
            reject('Invali JSON')
        }

        reader.readAsText(file)
    })
}

export const getRandom = (nb: number): number => {
    return Math.floor(Math.random() * nb)
}

export const generateArray = (n: number) => Array.from(Array(n).keys()).map(el => el + 1)

export function handleCircularReplacer() {
    const seen = new WeakSet()
    return (key: string, value: any) => {
        if (typeof value === 'object' && value !== null) {
            if (seen.has(value)) {
                return
            }
            seen.add(value)
        }
        return value
    }
}

export const getVideoFormat = (width: number | undefined, height: number | undefined): '9:16' | '16:9' => {
    if (!width || !height) return '16:9'
    if (width / height >= 16 / 9 - 0.1) return '16:9'
    return '9:16'
}

export const dateTodisponibilite = (d: any) => {
    const date = new Date(d)
    const formatter = new Intl.DateTimeFormat('fr-FR', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
    })
    return formatter.format(date)
}

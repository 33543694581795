import Http, { ApiRootType } from '../../Http/Http'
import { ResMessage } from '../../Mesvideo/models/MesvideoModel'
import { tRangePeriod } from '../../models/DateRange'
import {
    ContextBrandContent,
    PublishVideoFeed,
    RawVideoResponse,
    StatsFeed,
    StatsIdContext,
    Targeting,
    TargetingById,
    UpdateVideoFeed,
    VideoFeedById,
    VideoFeedFilter
} from '../models/BrandContentModel'

const http = new Http()

export class BrandContentService {
    async getVideoFeed(filter: VideoFeedFilter): Promise<RawVideoResponse> {
        const advertiserIds = filter.advertiser?.join(',')
        try {
            const response = await http.sendGet<RawVideoResponse>(`prog/video`, {...filter, advertiser: advertiserIds, sortDate: `broadcastAt:${filter.sortDate}`} , ApiRootType.two)
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getByIdVideoFeed(id: string): Promise<VideoFeedById> {
        try {
            const response = await http.sendGet<VideoFeedById>(`prog/video/${id}`, undefined, ApiRootType.two)
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getStatsById(id: string): Promise<StatsFeed[]> {
        try {
            const response = await http.get<StatsFeed[]>(`prog/video/${id}/stats`, undefined, ApiRootType.one)
            return response.data
        } catch (e) {
            throw e
        }
    }

    async publishVideo(item: PublishVideoFeed): Promise<PublishVideoFeed> {
        try {
            const response = await http.post<PublishVideoFeed>(`prog/video/upload`, item, { cancelToken: 'publish_video' }, ApiRootType.two)
            return response.data
        } catch (e) {
            throw e
        }
    }

    async putVideo(id: string, data: UpdateVideoFeed): Promise<UpdateVideoFeed> {
        try {
            const response = await http.put<UpdateVideoFeed>(`prog/video/${id}`, data, 6000, undefined, ApiRootType.two)
            return response.data
        } catch (e) {
            throw e
        }
    }

    async deletVideo(id: string): Promise<ResMessage> {
        try {
            const response = await http.delete(`prog/video/${id}`, 6000, ApiRootType.two)
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getCatalogAdvertiser(context: string, search?: string): Promise<ContextBrandContent[]> {
        try {
            const response = await http.sendGet<ContextBrandContent[]>(`prog/video/filters/${context}`, { search }, ApiRootType.two)
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getStatsByIdContext(id: string, context: string, p?:tRangePeriod): Promise<StatsIdContext>{
        try{
            const response= await http.get<StatsIdContext>(`prog/video/${id}/stats/${context}`,{params: {p}},ApiRootType.one)
            return response.data
        }
        catch(e){
            throw e
        }
    }

    async getTargetingById(id:number, limit: number, page: number):Promise<TargetingById>{
        try{
            const response= await http.sendGet<TargetingById>(`prog/video/${id}/targeting`,{ limit, page },ApiRootType.two)
            return response.data
        }
        catch(e){
            throw e
        }
    }

    async getTargeting():Promise<Targeting[]>{
        try{
            const response = await http.sendGet<Targeting[]>(`prog/video/targeting`,undefined, ApiRootType.two)
            return response.data
        }
        catch(e){
            throw e
        }
    }
    async deleteMultiple(ids: string[]): Promise<boolean>{
        try{
            const response = await http.post<boolean>(`prog/video/delete`, ids, { cancelToken: 'delete' } ,ApiRootType.two)
            return response.data
        }
        catch(e){
            throw e
        }
    }
}

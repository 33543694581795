import { createAsyncThunk } from '@reduxjs/toolkit'
import { BrandContentService } from '../BrandContentVideofeed/Service/BrandContentService'
import { PublishVideoFeed, UpdateVideoFeed, VideoFeedFilter } from '../BrandContentVideofeed/models/BrandContentModel'
import { tRangePeriod } from '../models/DateRange'

const brandContentService = new BrandContentService()

export const getAllVideo = createAsyncThunk('prog/video', async (filter: VideoFeedFilter) => {
    const dataVideoFeed = await brandContentService.getVideoFeed(filter)
    return dataVideoFeed
})

export const publishVideoFeed = createAsyncThunk('prog/video/upload', async (filter: PublishVideoFeed) => {
    const dataVideoFeed = await brandContentService.publishVideo(filter)
    return dataVideoFeed
})

export const updateVideoFeed = createAsyncThunk('prog/video/update', async (data: { id: string; filter: UpdateVideoFeed }) => {
    const dataVideoFeed = await brandContentService.putVideo(data.id, data.filter)
    return dataVideoFeed
})

export const deleteVideoFeed = createAsyncThunk('prog/video/delete', async (id: string) => {
    const dataVideoFeed = await brandContentService.deletVideo(id)
    return dataVideoFeed
})

export const videoFeedById = createAsyncThunk('prog/video/id', async (id: string) => {
    const dataVideoFeed = await brandContentService.getByIdVideoFeed(id)
    return dataVideoFeed
})

export const getContext = createAsyncThunk('prog/video/context', async (params: {context: string, search?: string}) => {
    const dataVideoFeed = await brandContentService.getCatalogAdvertiser(params.context, params.search)
    return dataVideoFeed
})

export const getStatsByIdContext = createAsyncThunk('prog/video/id/stats/context',async(params:{id:string, context: string, p?: tRangePeriod})=>{
    const dataStatsByIdContext= await brandContentService.getStatsByIdContext(params.id, params.context, params.p)
    return dataStatsByIdContext
})

export const getTargetingById = createAsyncThunk('prog/video/id/targeting', async(params:{id: number, limit: number, page: number})=>{
    const dataTargetingById= await brandContentService.getTargetingById(params.id, params.limit, params.page)
    return dataTargetingById
})

export const getTargeting = createAsyncThunk('prog/video/targeting',() => {
     return brandContentService.getTargeting()
})

export const getStatsById = createAsyncThunk('prog/video/id/stats', async(params:{id:string})=>{
    const dataStatsById = await brandContentService.getStatsById(params.id)
    return dataStatsById
})

export const deleteMultiple = createAsyncThunk('prog/video/deleteMultiple',async(ids: string[])=>{
    const deleteVideoMultiple = await brandContentService.deleteMultiple(ids)
    return deleteVideoMultiple
})
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import 'jsoneditor/dist/jsoneditor.css'
import * as serviceWorker from './serviceWorker'
import './i18n'
import { FallBackLoading } from './Components/FallabackLoading'
import { MainDigitekaErrorBoundary } from './Components/MainDigitekaErrorBoundary'
import componentLoader from './Utils/lazy'
import { Provider } from 'react-redux'
import { store } from './store'

const App = React.lazy(() => componentLoader(() => import('./App'), 2))

ReactDOM.render(
    <MainDigitekaErrorBoundary>
        <Suspense fallback={<FallBackLoading />}>
        <Provider store={store}>
            <App />
            </Provider>
        </Suspense>
    </MainDigitekaErrorBoundary>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister()

import { Range } from 'react-date-range'
import { tRangePeriod } from '../../models/DateRange'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import {
    deleteMultiple,
    deleteVideoFeed,
    getAllVideo,
    getContext,
    getStatsById,
    getStatsByIdContext,
    getTargeting,
    getTargetingById,
    publishVideoFeed,
    updateVideoFeed,
    videoFeedById
} from '../../Actions/BrandContentAction'
import { FetchStatusType } from '../storeType'
import {
    ContextBrandContent,
    PublishVideoFeed,
    RawVideoResponse,
    StatsFeed,
    StatsIdContext,
    StepOneValidation,
    Targeting,
    TargetingById,
    UpdateVideoFeed,
    VideoFeedById
} from '../../BrandContentVideofeed/models/BrandContentModel'
import { tSortMode } from '../../BrandContentVideofeed/Components/SortMenuBrandContent'

interface drawerType {
    title: string
    isOpen: boolean
}
export const defaultValueRange = {
    key: 'range',
    startDate: new Date(new Date().getTime() - 15 * 1000 * 60 * 60 * 24),
    endDate: new Date()
}

const initialDataStats = { id: 0, colspan: 0, dateRange: [], height: 0, pinned: false, title: '', tooltip: '', type: '', data: [] }

const initialDataDrawer: drawerType = {
    title: "",
    isOpen: false
}

export const defaultPublish = {
    advertiser: null,
    advertiserThumbnails: null,
    broadcastDuration: null,
    broadcastStartDate: new Date(),
    catalog: null,
    description: null,
    disponibility: 0,
    lang: 'fr',
    encoded: 0,
    pendingDisponibility: 0,
    redirectUrl: '',
    subtitleFile: null,
    targeting: [],
    temporaryId: 0,
    title: '',
    type: 'video',
    videoDuration: 0,
}

interface BrandContentState {
    range: Range
    period: tRangePeriod
    openDrawer: drawerType
    loadingVideo: FetchStatusType
    loadingPublish: FetchStatusType
    loadingUpdate: FetchStatusType
    loadingDelete: FetchStatusType
    loadingGetById: FetchStatusType
    loadingContext: FetchStatusType
    loadingStatsByIdContext: FetchStatusType
    loadingTargetingById: FetchStatusType
    loadingTargeting: FetchStatusType
    loadingStats: FetchStatusType
    loadingDeleteMultiple: FetchStatusType
    videoFeedLists: RawVideoResponse
    videoSingle: VideoFeedById | undefined
    dataCatalogs: ContextBrandContent[]
    dataAdvertiser: ContextBrandContent[]
    dataPublish: PublishVideoFeed
    dataStreamEvo: StatsIdContext
    dataStreamVideoFeed: StatsIdContext
    dataNbrClick: StatsIdContext
    dataCompletion: StatsIdContext
    dataTargetingById: TargetingById
    dataTargeting: Targeting[]
    validationStepOne: StepOneValidation
    advertiserChecked: number[]
    dataStats: StatsFeed[]
    activeFilter: number
    sortValue: tSortMode 
    searchValue: string 
    rowsPerPage: number
    page: number
    reload: number
    newDataEdit: UpdateVideoFeed | undefined
    oldDataEdit: UpdateVideoFeed | undefined
    idTargeting: number | undefined
    checkedFilter: number[]
    refreshEdit: number
    noSubmited: boolean
    errorDays: boolean
}

const initialState: BrandContentState = {
    range: defaultValueRange,
    noSubmited: false,
    period: '-15d',
    openDrawer: initialDataDrawer,
    loadingVideo: FetchStatusType.IDLE,
    loadingUpdate: FetchStatusType.IDLE,
    loadingPublish: FetchStatusType.IDLE,
    loadingDelete: FetchStatusType.IDLE,
    loadingGetById: FetchStatusType.IDLE,
    loadingContext: FetchStatusType.IDLE,
    loadingStatsByIdContext: FetchStatusType.IDLE,
    loadingTargetingById: FetchStatusType.IDLE,
    loadingTargeting: FetchStatusType.IDLE,
    loadingStats: FetchStatusType.IDLE,
    loadingDeleteMultiple: FetchStatusType.IDLE,
    dataStreamEvo:initialDataStats,
    dataStreamVideoFeed:initialDataStats,
    dataNbrClick:initialDataStats,
    dataCompletion:initialDataStats,
    dataCatalogs: [],
    dataAdvertiser: [],
    videoFeedLists: {
        nbrVideos: 0,
        videos: [],
        metadata: {
            page: 1,
            results_per_page: 0,
            total: 0
        }
    },
    videoSingle: undefined,
    dataPublish: defaultPublish,
    dataTargetingById: {
        sites: [],
        metadata: {
            page: 0,
            results_per_page: 0,
            total: 0
        }
    },
    dataTargeting: [],
    dataStats:[],
    validationStepOne: {
        advertiser: false,
        catalog: false,
        redirectUrl: false,
        title: false
    },
    advertiserChecked: [],
    activeFilter: 0,
    sortValue: 'DESC',
    searchValue: '',
    rowsPerPage: 10,
    page: 0,
    reload: 0,
    newDataEdit: undefined,
    oldDataEdit: undefined,
    idTargeting: undefined,
    checkedFilter: [],
    refreshEdit: 0,
    errorDays: false
}

export const BrandContentSlice = createSlice({
    name: 'brandContent',
    initialState,
    reducers: {
        setRangeBrandContent(state, action: PayloadAction<Range>) {
            return { ...state, range: action.payload }
        },
        setPeriodBrandContent(state, action: PayloadAction<tRangePeriod>) {
            return { ...state, period: action.payload }
        },
        setOpenDrawer(state, action: PayloadAction<drawerType>) {
            return { ...state, openDrawer: action.payload }
        },
        setDataPublish(state, action: PayloadAction<PublishVideoFeed>) {
            return { ...state, dataPublish: action.payload }
        },
        setValidationStepOne(state, action: PayloadAction<StepOneValidation>) {
            return { ...state, validationStepOne: action.payload }
        },
        setLoadingDefault(state, action: PayloadAction<FetchStatusType>) {
            return { ...state, loadingPublish: action.payload }
        },
        setAdvertiserChecked(state, action: PayloadAction<number[]>) {
            return { ...state, advertiserChecked: action.payload }
        },
        setActiveFilter(state, action: PayloadAction<number>) {
            return { ...state, activeFilter: action.payload }
        },
        setSortMode(state, action: PayloadAction<tSortMode>) {
            return { ...state, sortValue: action.payload }
        },
        setSearchValue(state, action: PayloadAction<string>) {
            return { ...state, searchValue: action.payload }
        },
        setReloadTable(state, action: PayloadAction<number>) {
            return { ...state, reload: action.payload }
        },
        setRowsPerPage(state, action: PayloadAction<number>){
            return {...state, rowsPerPage: action.payload}
        },
        setPage(state, action: PayloadAction<number>){
            return {...state, page: action.payload}
        },
        setNewDataEdit(state, action: PayloadAction<UpdateVideoFeed | undefined>){
            return {...state, newDataEdit: action.payload}
        },
        setOldDataEdit(state, action: PayloadAction<UpdateVideoFeed>){
            return {...state, oldDataEdit: action.payload}
        },
        setVideoSingleUndefined(state, action: PayloadAction<VideoFeedById | undefined>){
            return {...state, videoSingle: action.payload}
        },
        setIdTargeting(state, action: PayloadAction<number | undefined>){
            return {...state, idTargeting: action.payload}
        },
        setCheckedFilter(state, action: PayloadAction<number[]>){
            return {...state, checkedFilter: action.payload}
        },
        setSubmited(state, action: PayloadAction<boolean>){
            return {...state, noSubmited: action.payload}
        },
        setLoadingUpdateDefault(state, action: PayloadAction<FetchStatusType>){
            return {...state, loadingUpdate: action.payload}
        },
        setErrorDays(state, action: PayloadAction<boolean>){
            return {...state, errorDays: action.payload}
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getAllVideo.pending, state => {
                return { ...state, loadingVideo: FetchStatusType.LOADING }
            })
            .addCase(getAllVideo.fulfilled, (state, action) => {
                return { ...state, loadingVideo: FetchStatusType.COMPLETED, videoFeedLists: action.payload }
            })
            .addCase(getAllVideo.rejected, state => {
                return { ...state, loadingVideo: FetchStatusType.FAILED }
            })
        builder
            .addCase(publishVideoFeed.pending, state => {
                return { ...state, loadingPublish: FetchStatusType.LOADING }
            })
            .addCase(publishVideoFeed.fulfilled, (state, action) => {
                return { ...state, loadingPublish: FetchStatusType.COMPLETED, dataPublish: {...state.dataPublish, id: action.payload.id} }
            })
            .addCase(publishVideoFeed.rejected, state => {
                return { ...state, loadingPublish: FetchStatusType.FAILED }
            })
        builder
            .addCase(updateVideoFeed.pending, state => {
                return { ...state, loadingUpdate: FetchStatusType.LOADING }
            })
            .addCase(updateVideoFeed.fulfilled, (state, action) => {
                return { ...state, loadingUpdate: FetchStatusType.COMPLETED, refreshEdit: state.refreshEdit + 1 }
            })
            .addCase(updateVideoFeed.rejected, state => {
                return { ...state, loadingUpdate: FetchStatusType.FAILED }
            })
        builder
            .addCase(deleteVideoFeed.pending, state => {
                return { ...state, loadingDelete: FetchStatusType.LOADING }
            })
            .addCase(deleteVideoFeed.fulfilled, (state, action) => {
                return { ...state, loadingDelete: FetchStatusType.COMPLETED }
            })
            .addCase(deleteVideoFeed.rejected, state => {
                return { ...state, loadingDelete: FetchStatusType.FAILED }
            })
        builder
            .addCase(videoFeedById.pending, state => {
                return { ...state, loadingGetById: FetchStatusType.LOADING }
            })
            .addCase(videoFeedById.fulfilled, (state, action) => {
                return { ...state, loadingGetById: FetchStatusType.COMPLETED, videoSingle: action.payload }
            })
            .addCase(videoFeedById.rejected, state => {
                return { ...state, loadingGetById: FetchStatusType.FAILED }
            })
        builder
            .addCase(getContext.pending, state => {
                return { ...state, loadingContext: FetchStatusType.LOADING }
            })
            .addCase(getContext.fulfilled, (state, action) => {
                const { context } = action.meta.arg
                if (context === 'catalog') {
                    return { ...state, loadingContext: FetchStatusType.COMPLETED, dataCatalogs: action.payload }
                } else if (context === 'advertiser') {
                    return { ...state, loadingContext: FetchStatusType.COMPLETED, dataAdvertiser: action.payload }
                }
            })
            .addCase(getContext.rejected, state => {
                return { ...state, loadingContext: FetchStatusType.FAILED }
            })
        builder
            .addCase(getStatsByIdContext.pending, state => {
                return { ...state, loadingStatsByIdContext: FetchStatusType.LOADING }
            })
            .addCase(getStatsByIdContext.fulfilled, (state, action) => {
                const { context } = action.meta.arg
                if (context === 'streamEvo') {
                    return { ...state, loadingStatsByIdContext: FetchStatusType.COMPLETED, dataStreamEvo: action.payload }
                } else if (context === 'streamVideofeed') {
                    return { ...state, loadingStatsByIdContext: FetchStatusType.COMPLETED, dataStreamVideoFeed: action.payload }
                } else if (context === 'completion') {
                    return { ...state, loadingStatsByIdContext: FetchStatusType.COMPLETED, dataCompletion: action.payload }
                } else if (context === 'nbrClick') {
                    return { ...state, loadingStatsByIdContext: FetchStatusType.COMPLETED, dataNbrClick: action.payload }
                }
            })
            .addCase(getStatsByIdContext.rejected, state => {
                return { ...state, loadingStatsByIdContext: FetchStatusType.FAILED }
            })
        builder
            .addCase(getTargetingById.pending, state => {
                return { ...state, loadingTargetingById: FetchStatusType.LOADING }
            })
            .addCase(getTargetingById.fulfilled, (state, action) => {
                return { ...state, loadingTargetingById: FetchStatusType.COMPLETED, dataTargetingById: action.payload }
            })
            .addCase(getTargetingById.rejected, state => {
                return { ...state, loadingTargetingById: FetchStatusType.FAILED }
            })
        builder
            .addCase(getTargeting.pending, state => {
                return { ...state, loadingTargeting: FetchStatusType.LOADING }
            })
            .addCase(getTargeting.fulfilled, (state, action) => {
                return { ...state, loadingTargeting: FetchStatusType.COMPLETED, dataTargeting: action.payload }
            })
            .addCase(getTargeting.rejected, state => {
                return { ...state, loadingTargeting: FetchStatusType.FAILED }
            })
        builder
            .addCase(getStatsById.pending, state => {
                return { ...state, loadingStats: FetchStatusType.LOADING }
            })
            .addCase(getStatsById.fulfilled, (state, action) => {
                return { ...state, loadingStats: FetchStatusType.COMPLETED, dataStats: action.payload }
            })
            .addCase(getStatsById.rejected, state => {
                return { ...state, loadingStats: FetchStatusType.FAILED }
            })
        builder
            .addCase(deleteMultiple.pending, state => {
                return {...state, loadingDeleteMultiple: FetchStatusType.LOADING}
            })
            .addCase(deleteMultiple.fulfilled, (state, action)=>{
                return {...state, loadingDeleteMultiple: FetchStatusType.COMPLETED}
            })
            .addCase(deleteMultiple.rejected, state =>{
                return{...state, loadingDeleteMultiple: FetchStatusType.FAILED}
            })
    }
})

export const {
    setRangeBrandContent,
    setLoadingDefault,
    setAdvertiserChecked,
    setPeriodBrandContent,
    setOpenDrawer,
    setDataPublish,
    setValidationStepOne,
    setActiveFilter,
    setSortMode,
    setSearchValue,
    setRowsPerPage,
    setPage,
    setReloadTable,
    setNewDataEdit,
    setOldDataEdit,
    setVideoSingleUndefined,
    setIdTargeting,
    setCheckedFilter,
    setSubmited,
    setLoadingUpdateDefault,
    setErrorDays
} = BrandContentSlice.actions

export const rangeBrandContent = (state: RootState) => state.brandContent.range

export const periodBrandContent = (state: RootState) => state.brandContent.period

export const drawerOpen = (state: RootState) => state.brandContent.openDrawer

export const loadingVideo = (state: RootState) => state.brandContent.loadingVideo

export const loadingPublish = (state: RootState) => state.brandContent.loadingPublish

export const videoFeedLists = (state: RootState) => state.brandContent.videoFeedLists

export const loadingUpdate = (state: RootState) => state.brandContent.loadingUpdate

export const loadingDelete = (state: RootState) => state.brandContent.loadingDelete

export const loadingGetById = (state: RootState) => state.brandContent.loadingGetById

export const loadingStats = (state: RootState) => state.brandContent.loadingStats

export const loadingDeleteMultiple = (state: RootState) => state.brandContent.loadingDeleteMultiple

export const videoFeedSingle = (state: RootState) => state.brandContent.videoSingle

export const dataCatalog = (state: RootState) => state.brandContent.dataCatalogs

export const dataAdvertiser = (state: RootState) => state.brandContent.dataAdvertiser

export const dataPublish = (state: RootState) => state.brandContent.dataPublish

export const dataStreamEvo = (state: RootState) => state.brandContent.dataStreamEvo

export const dataStreamVideoFeed = (state: RootState) => state.brandContent.dataStreamVideoFeed

export const dataCompletion = (state: RootState) => state.brandContent.dataCompletion

export const dataNbrClick = (state: RootState) => state.brandContent.dataNbrClick

export const dataTargetingById = (state: RootState) => state.brandContent.dataTargetingById

export const dataTargeting = (state: RootState) => state.brandContent.dataTargeting

export const loadingStatsByIdContext = (state: RootState) => state.brandContent.loadingStatsByIdContext

export const validationStepOne = (state: RootState) => state.brandContent.validationStepOne

export const advertiserChecked = (state: RootState) => state.brandContent.advertiserChecked

export const dataStats = (state: RootState) => state.brandContent.dataStats

export const activeFilter = (state: RootState) => state.brandContent.activeFilter

export const sortValue = (state: RootState) => state.brandContent.sortValue

export const searchValue = (state: RootState) => state.brandContent.searchValue

export const rowPerPage = (state: RootState) => state.brandContent.rowsPerPage

export const pages = (state: RootState) => state.brandContent.page

export const reload = (state: RootState) => state.brandContent.reload

export const newDataEdit = (state: RootState) => state.brandContent.newDataEdit

export const oldDataEdit = (state: RootState) => state.brandContent.oldDataEdit

export const idTargeting = (state: RootState) => state.brandContent.idTargeting

export const checkedFilter = (state: RootState) => state.brandContent.checkedFilter

export const loadingTargetingById = (state: RootState) => state.brandContent.loadingTargetingById

export const refreshEdit = (state: RootState) => state.brandContent.refreshEdit

export const noSubmited = (state: RootState) => state.brandContent.noSubmited

export const errorDays = (state: RootState) => state.brandContent.errorDays

export default BrandContentSlice.reducer

import Http, { ApiRootType } from '../../../Http/Http'
import {
    BidDetailByBidder,
    Bidders,
    BlocDashboard,
    ChartPie,
    DataMixedType,
    DataTableErrorCode,
    DimensionSite,
    DimensionTag,
    FiltreFixe,
    InputBlocs,
    PerformanceTag
} from '../Models/AuditTagModel'

const http = new Http()
export class AuditTagService {
    static BLOC_1 = 'bloc_1'
    static BLOC_2 = 'bloc_2'
    static BLOC_3 = 'bloc_3'
    static BLOC_4 = 'bloc_4'
    static SITE = 'site_api'
    static TAG = 'tag_api'
    static BLOC_1_HB = 'bloc_1_hb'
    static BLOC_2_HB = 'bloc_2_hb'
    static BLOC_3_HB = 'bloc_3_hb'
    static SITE_HB = 'site_hb'
    static BIDDER = 'bidder_hb'

    async getFilterFixe(context: string): Promise<FiltreFixe> {
        try {
            const response = await http.get<FiltreFixe>(`audit/${context}/filters`, undefined, ApiRootType.two)
            return response.data
        } catch (e) {
            throw e
        }
    }

    async FilterSiteByTags(tags: number[], context: string): Promise<DimensionSite> {
        const data = {
            tags: tags
        }
        try {
            const response = await http.post<DimensionSite>(
                `audit/${context}/filters/company/sites`,
                data,
                { cancelToken: AuditTagService.SITE },
                ApiRootType.two
            )
            return response.data
        } catch (error) {
            throw error
        }
    }

    async FilterSiteByBidder(bidder: string[], context: string): Promise<DimensionSite> {
        const data = {
            bidder: bidder
        }
        try {
            const response = await http.post<DimensionSite>(
                `audit/${context}/filters/company/sites`,
                data,
                { cancelToken: AuditTagService.SITE_HB },
                ApiRootType.two
            )
            return response.data
        } catch (error) {
            throw error
        }
    }

    async FilterBidderSites(sites: string[], context: string): Promise<Bidders> {
        const data = {
            sites: sites
        }
        try {
            const response = await http.post<Bidders>(
                `audit/${context}/filters/bidder`,
                data,
                { cancelToken: AuditTagService.TAG },
                ApiRootType.two
            )
            return response.data
        } catch (error) {
            throw error
        }
    }

    async FilterTagBySite(sites: number[], context: string): Promise<DimensionTag> {
        const data = {
            sites: sites
        }
        try {
            const response = await http.post<DimensionTag>(
                `audit/${context}/filters/regie/tags`,
                data,
                { cancelToken: AuditTagService.TAG },
                ApiRootType.two
            )
            return response.data
        } catch (error) {
            throw error
        }
    }

    async getParamBlocDashboard(context: string): Promise<BlocDashboard[]> {
        try {
            const response = await http.get<BlocDashboard[]>(`audit/${context}/blocks`, undefined, ApiRootType.two)
            return response.data
        } catch (e) {
            throw e
        }
    }
    async FilterBlocDashboard(id: number, from: string, to: string, filters: InputBlocs, context: string): Promise<PerformanceTag> {
        try {
            const response = await http.post<PerformanceTag>(
                `audit/${context}/blocks/${id}/${from}/${to}`,
                filters,
                { cancelToken: AuditTagService.BLOC_1 },
                ApiRootType.two
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async FilterMixedBlocDashboard(id: number, from: string, to: string, filters: InputBlocs, context: string): Promise<DataMixedType> {
        try {
            const response = await http.post<DataMixedType>(
                `audit/${context}/blocks/${id}/${from}/${to}`,
                filters,
                { cancelToken: AuditTagService.BLOC_2 },
                ApiRootType.two
            )
            return response.data
        } catch (e) {
            throw e
        }
    }
    async FilterPieDashboard(id: number, from: string, to: string, filters: InputBlocs, context: string): Promise<ChartPie> {
        try {
            const response = await http.post<ChartPie>(
                `audit/${context}/blocks/${id}/${from}/${to}`,
                filters,
                { cancelToken: AuditTagService.BLOC_3 },
                ApiRootType.two
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async FilterTableErrorCode(
        id: number,
        from: string,
        to: string,
        dimension: string,
        filters: InputBlocs,
        context: string
    ): Promise<DataTableErrorCode> {
        try {
            const response = await http.post<DataTableErrorCode>(
                `audit/${context}/blocks/${id}/${from}/${to}/${dimension}`,
                filters,
                { cancelToken: AuditTagService.BLOC_4 },
                ApiRootType.two
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    // AuditHB

    async FilterBlocErrorHB(id: number, from: string, to: string, filters: InputBlocs, context: string): Promise<DataTableErrorCode> {
        try {
            const response = await http.post<DataTableErrorCode>(
                `audit/${context}/blocks/${id}/${from}/${to}`,
                filters,
                { cancelToken: AuditTagService.BLOC_3_HB },
                ApiRootType.two
            )
            return response.data
        } catch (e) {
            throw e
        }
    }
    async FilterFirstBloc(
        id: number,
        from: string,
        to: string,
        dimension: string,
        filters: InputBlocs,
        context: string
    ): Promise<DataTableErrorCode> {
        try {
            const response = await http.post<DataTableErrorCode>(
                `audit/${context}/blocks/${id}/${from}/${to}/${dimension}`,
                filters,
                { cancelToken: AuditTagService.BLOC_1_HB },
                ApiRootType.two
            )
            return response.data
        } catch (e) {
            throw e
        }
    }
    async FilterSecondBlocAuditHB(id: number, from: string, to: string, filters: InputBlocs, context: string): Promise<BidDetailByBidder> {
        try {
            const response = await http.post<BidDetailByBidder>(
                `audit/${context}/blocks/${id}/${from}/${to}`,
                filters,
                { cancelToken: AuditTagService.BLOC_2_HB },
                ApiRootType.two
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    // AuditHB

    static abortAllBlock() {
        http.abortById(AuditTagService.BLOC_1)
        http.abortById(AuditTagService.BLOC_2)
        http.abortById(AuditTagService.BLOC_3)
        http.abortById(AuditTagService.BLOC_4)
    }
    static abortSiteTag() {
        http.abortById(AuditTagService.SITE)
        http.abortById(AuditTagService.TAG)
    }

    static abortAllBlockHb() {
        http.abortById(AuditTagService.BLOC_1_HB)
        http.abortById(AuditTagService.BLOC_2_HB)
        http.abortById(AuditTagService.BLOC_3_HB)
    }
    static abortSiteBidder() {
        http.abortById(AuditTagService.SITE_HB)
        http.abortById(AuditTagService.BIDDER)
    }
}

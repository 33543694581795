/**
 *
 * @param {string} str
 */
export const trunc = str => {
    if (str?.length > 150) return `${str?.substring(0, 150)} ...`
    return str
}

export const checkEmail = email => {
    let re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
}

import { AxiosResponse } from 'axios'
import diff from 'date-fns/differenceInSeconds'
import add from 'date-fns/addSeconds'
import moment from 'moment'

interface CacheItem<T> {
    expired: Date
    response: AxiosResponse<T>
}

interface RawCacheItem<T> {
    expired: string
    response: AxiosResponse<T>
}

export class CacheHandler {
    headerName: string
    constructor(headerName: string) {
        this.headerName = headerName
    }

    getItem<T>(url: string): AxiosResponse<T> | null {
        let key = `httpcache_${url}`
        let item = localStorage.getItem(key)
        if (!item) {
            return null
        }
        let obj: RawCacheItem<T> = JSON.parse(item)
        let now = new Date()
        if (diff(moment(obj.expired).toDate(), now) < 0) {
            localStorage.removeItem(key)
            return null
        }
        return obj.response
    }

    setItem<T>(url: string, response: AxiosResponse<T>) {
        if (response.headers[this.headerName]) {
            let expired: number = response.headers[this.headerName]
            let obj: CacheItem<T> = {
                expired: add(new Date(), expired),
                response: response
            }
            localStorage.setItem(`httpcache_${url}`, JSON.stringify(obj))
        }
    }

    deleteItem(url: string) {
        localStorage.removeItem(`httpcache_${url}`)
    }
}

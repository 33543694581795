import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Range } from 'react-date-range'
import { RootState } from '..'
import { tRangePeriod } from '../../models/DateRange'
import {
    Bidders,
    BidDetailByBidder,
    BlocDashboard,
    DataMixedType,
    DataTableErrorCode,
    DimensionSite,
    FiltreFixe,
    Header
} from '../../Components/AuditTag/Models/AuditTagModel'
import {
    FilterBidDetailByBidder,
    FilterBlocErrorHB,
    FiltreTablePerfByBidder,
    getAllBlocks,
    getBidder,
    getFiltersGlobal,
    getSiteBidder
} from '../../Actions/AuditHb'
import { FetchStatusType } from '../storeType'

export const defaultValueRange = {
    key: 'range',
    startDate: new Date(new Date().getTime() - 15 * 1000 * 60 * 60 * 24),
    endDate: new Date()
}
const sortByDefault: Header = {
    aggregation: null,
    id: 'nbr_calls',
    title: "Nombre d'appels",
    unit: null
}
const sortByBidDefault: Header = {
    title: "",
    id: "nbr_calls",
    unit: null,
    aggregation: null,
    className: ""
}
const sortByErrorDefault: Header = {
    title: "",
    id: "nbr_error",
    unit: null,
    aggregation: null,
    className: "",
    description:""
}
const defaultDataPerf: DataTableErrorCode = {
    type: 'table',
    data: {
        availableDimensions: [],
        headers: [],
        rows: []
    },
    metadata: {
        page: 0,
        results_per_page: 0,
        total: 0
    }
}
const defaultBidDetail: BidDetailByBidder = {
    type: 'table',
    data: {
        headers: [],
        rows: []
    },
    metadata: {
        page: 0,
        results_per_page: 0,
        total: 0
    }
}
const dataMixedDefault = {
    type: 'mixed',
    data: []
}

interface ListCompanyState {
    refreshSite: number
    refreshBidder: number
    siteSelected: string[]
    bidderSelected: string[]
    countrySelected: string[]
    navigatorSelected: string[]
    deviceSelected: string[]
    osSelected: string[]
    filters: FiltreFixe
    statusBlock: FetchStatusType
    statusPerfByBidder: FetchStatusType
    statusBidDetailByBidder: FetchStatusType
    statusErrorCode: FetchStatusType
    statusFilters: FetchStatusType
    statusSitesByBidder: FetchStatusType
    statusBidder: FetchStatusType
    range: Range
    period: tRangePeriod
    dimension: string
    order: 'asc' | 'desc'
    orderBid: 'asc' | 'desc'
    orderError: 'asc' | 'desc'
    sortBy: Header
    sortByBid: Header
    sortByError: Header
    sites: any[]
    bidder: string[]
    countries: any[]
    devices: string[]
    navigator: string[]
    os: string[]
    dataPerfByBidder: DataTableErrorCode
    dataBidDetailByBidder: BidDetailByBidder
    dataGraphLine: DataMixedType
    rowsPerPage: number
    rowsPerPageErrorCode: number
    page: number
    pageError: number
    dataErrorCode: DataTableErrorCode
    blocks: BlocDashboard[]
    dataSites: DimensionSite
    dataBidder: Bidders
    selectedCompanies: string[]
    selectedBidders: string[]
    selectedCountries: string[]
    selectedNavigator: string[]
    selectedDevice: string[]
    selectedOs: string[]
    refreshGlobal: number
}

const initialState: ListCompanyState = {
    siteSelected: [],
    navigatorSelected: [],
    selectedOs: [],
    selectedCountries: [],
    selectedNavigator: [],
    selectedDevice: [],
    selectedBidders: [],
    osSelected: [],
    deviceSelected: [],
    countrySelected: [],
    statusBidder: FetchStatusType.IDLE,
    selectedCompanies: [],
    bidderSelected: [],
    statusSitesByBidder: FetchStatusType.IDLE,
    statusFilters: FetchStatusType.IDLE,
    statusBlock: FetchStatusType.IDLE,
    statusPerfByBidder: FetchStatusType.IDLE,
    statusBidDetailByBidder: FetchStatusType.IDLE,
    statusErrorCode: FetchStatusType.IDLE,
    range: defaultValueRange,
    period: '-15d',
    dimension: 'dimension_nbr_calls',
    order: 'desc',
    orderBid: 'desc',
    sortByBid: sortByBidDefault,
    sortBy: sortByDefault,
    sortByError: sortByErrorDefault,
    sites: [],
    bidder: [],
    countries: [],
    devices: [],
    navigator: [],
    os: [],
    dataPerfByBidder: defaultDataPerf,
    dataBidDetailByBidder: defaultBidDetail,
    dataGraphLine: dataMixedDefault,
    rowsPerPage: 10,
    page: 0,
    pageError: 0,
    refreshGlobal: 0,
    dataErrorCode: defaultDataPerf,
    rowsPerPageErrorCode: 10,
    orderError: 'desc',
    blocks: [],
    filters: {
        country: {
            id: '',
            data: []
        },
        devices: {
            id: '',
            data: []
        },
        navigator: {
            id: '',
            data: []
        },
        os: {
            id: '',
            data: []
        }
    },
    dataSites: { id: '', data: [] },
    dataBidder: { id: '', data: [] },
    refreshSite: 0,
    refreshBidder: 0
}

export const auditHbSlice = createSlice({
    name: 'auditHb',
    initialState,
    reducers: {
        setRange(state, action: PayloadAction<Range>) {
            return { ...state, range: action.payload }
        },
        setPeriod(state, action: PayloadAction<tRangePeriod>) {
            return { ...state, period: action.payload }
        },
        setDimension(state, action: PayloadAction<string>) {
            return { ...state, dimension: action.payload }
        },
        setOrder(state, action: PayloadAction<'asc' | 'desc'>) {
            return { ...state, order: action.payload }
        },
        setOrderBid(state, action: PayloadAction<'asc' | 'desc'>) {
            return { ...state, orderBid: action.payload }
        },
        setSortBy(state, action: PayloadAction<Header>) {
            return { ...state, sortBy: action.payload }
        },
        setSortByBid(state, action: PayloadAction<Header>) {
            return { ...state, sortByBid: action.payload }
        },
        setSortByError(state, action: PayloadAction<Header>) {
            return { ...state, sortByError: action.payload }
        },
        setSites(state, action: PayloadAction<any[]>) {
            return { ...state, sites: action.payload }
        },
        setBidder(state, action: PayloadAction<string[]>) {
            return { ...state, bidder: action.payload }
        },
        setCountries(state, action: PayloadAction<any[]>) {
            return { ...state, countries: action.payload }
        },
        setDevices(state, action: PayloadAction<string[]>) {
            return { ...state, devices: action.payload }
        },
        setNavigator(state, action: PayloadAction<string[]>) {
            return { ...state, navigator: action.payload }
        },
        setOs(state, action: PayloadAction<string[]>) {
            return { ...state, os: action.payload }
        },
        setDataGraphLine(state, action: PayloadAction<DataMixedType>) {
            return { ...state, dataGraphLine: action.payload }
        },
        setRowsPerPage(state, action: PayloadAction<number>) {
            return { ...state, rowsPerPage: action.payload }
        },
        setPage(state, action: PayloadAction<number>) {
            return { ...state, page: action.payload }
        },
        setRowPerPageErrorCode(state, action: PayloadAction<number>) {
            return { ...state, rowsPerPageErrorCode: action.payload }
        },
        setPageError(state, action: PayloadAction<number>) {
            return { ...state, pageError: action.payload }
        },
        setOrderErrorCode(state, action: PayloadAction<'asc' | 'desc'>) {
            return { ...state, orderError: action.payload }
        },
        setSiteSelected(state, action: PayloadAction<string[]>) {
            return { ...state, siteSelected: action.payload }
        },
        setRefreshSite(state, action: PayloadAction<number>) {
            return { ...state, refreshSite: action.payload }
        },
        setSelectedCompanies(state, action: PayloadAction<string[]>) {
            return { ...state, selectedCompanies: action.payload }
        },
        setSelectedBidder(state, action: PayloadAction<string[]>) {
            return { ...state, bidderSelected: action.payload }
        },
        setCheckedBidder(state, action: PayloadAction<string[]>) {
            return { ...state, selectedBidders: action.payload }
        },
        setCheckedCountries(state, action: PayloadAction<string[]>) {
            return { ...state, selectedCountries: action.payload }
        },
        setCheckedOs(state, action: PayloadAction<string[]>) {
            return { ...state, selectedOs: action.payload }
        },
        setCheckedNavigator(state, action: PayloadAction<string[]>) {
            return { ...state, selectedNavigator: action.payload }
        },
        setCheckedDevice(state, action: PayloadAction<string[]>) {
            return { ...state, selectedDevice: action.payload }
        },
        setRefreshBidder(state, action: PayloadAction<number>) {
            return { ...state, refreshBidder: action.payload }
        },
        setSelectedCountry(state, action: PayloadAction<string[]>) {
            return { ...state, countrySelected: action.payload }
        },
        setSelectedNavigator(state, action: PayloadAction<string[]>) {
            return { ...state, navigatorSelected: action.payload }
        },
        setSelectedDevice(state, action: PayloadAction<string[]>) {
            return { ...state, deviceSelected: action.payload }
        },
        setSelectedOs(state, action: PayloadAction<string[]>) {
            return { ...state, osSelected: action.payload }
        },
        setRefreshGlobal(state, action: PayloadAction<number>) {
            return { ...state, refreshGlobal: action.payload }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getAllBlocks.pending, state => {
                return { ...state, statusBlock: FetchStatusType.LOADING }
            })
            .addCase(getAllBlocks.fulfilled, (state, action) => {
                return { ...state, statusBlock: FetchStatusType.COMPLETED, blocks: action.payload }
            })
            .addCase(getAllBlocks.rejected, state => {
                return { ...state, statusBlock: FetchStatusType.FAILED }
            })
        builder
            .addCase(FiltreTablePerfByBidder.pending, state => {
                return { ...state, statusPerfByBidder: FetchStatusType.LOADING }
            })
            .addCase(FiltreTablePerfByBidder.fulfilled, (state, action) => {
                return { ...state, statusPerfByBidder: FetchStatusType.COMPLETED, dataPerfByBidder: action.payload }
            })
            .addCase(FiltreTablePerfByBidder.rejected, state => {
                return { ...state, statusPerfByBidder: FetchStatusType.FAILED }
            })
        builder
            .addCase(FilterBidDetailByBidder.pending, state => {
                return { ...state, statusBidDetailByBidder: FetchStatusType.LOADING }
            })
            .addCase(FilterBidDetailByBidder.fulfilled, (state, action) => {
                return { ...state, statusBidDetailByBidder: FetchStatusType.COMPLETED, dataBidDetailByBidder: action.payload }
            })
            .addCase(FilterBidDetailByBidder.rejected, state => {
                return { ...state, statusBidDetailByBidder: FetchStatusType.FAILED }
            })
        builder
            .addCase(FilterBlocErrorHB.pending, state => {
                return { ...state, statusErrorCode: FetchStatusType.LOADING }
            })
            .addCase(FilterBlocErrorHB.fulfilled, (state, action) => {
                return { ...state, statusErrorCode: FetchStatusType.COMPLETED, dataErrorCode: action.payload }
            })
            .addCase(FilterBlocErrorHB.rejected, state => {
                return { ...state, statusErrorCode: FetchStatusType.FAILED }
            })
        builder
            .addCase(getFiltersGlobal.pending, state => {
                return { ...state, statusFilters: FetchStatusType.LOADING }
            })
            .addCase(getFiltersGlobal.fulfilled, (state, action) => {
                return { ...state, statusFilters: FetchStatusType.COMPLETED, filters: action.payload }
            })
            .addCase(getFiltersGlobal.rejected, state => {
                return { ...state, statusFilters: FetchStatusType.FAILED }
            })
        builder
            .addCase(getSiteBidder.pending, state => {
                return { ...state, statusSitesByBidder: FetchStatusType.LOADING }
            })
            .addCase(getSiteBidder.fulfilled, (state, action) => {
                return { ...state, statusSitesByBidder: FetchStatusType.COMPLETED, dataSites: action.payload }
            })
            .addCase(getSiteBidder.rejected, state => {
                return { ...state, statusSitesByBidder: FetchStatusType.FAILED }
            })
        builder
            .addCase(getBidder.pending, state => {
                return { ...state, statusBidder: FetchStatusType.LOADING }
            })
            .addCase(getBidder.fulfilled, (state, action) => {
                return { ...state, statusBidder: FetchStatusType.COMPLETED, dataBidder: action.payload }
            })
            .addCase(getBidder.rejected, state => {
                return { ...state, statusBidder: FetchStatusType.FAILED }
            })
    }
})

export const {
    setRange,
    setPeriod,
    setDimension,
    setOrder,
    setSortBy,
    setSites,
    setBidder,
    setCountries,
    setDevices,
    setNavigator,
    setOs,
    setDataGraphLine,
    setRowsPerPage,
    setPage,
    setRowPerPageErrorCode,
    setPageError,
    setOrderErrorCode,
    setSiteSelected,
    setRefreshSite,
    setSelectedCompanies,
    setSelectedBidder,
    setRefreshBidder,
    setSelectedCountry,
    setSelectedNavigator,
    setSelectedDevice,
    setSelectedOs,
    setCheckedBidder,
    setCheckedCountries,
    setCheckedNavigator,
    setCheckedDevice,
    setCheckedOs,
    setOrderBid,
    setSortByBid,
    setSortByError,
    setRefreshGlobal
} = auditHbSlice.actions

export const rangeHb = (state: RootState) => state.auditHb.range

export const periodHb = (state: RootState) => state.auditHb.period

export const dimension = (state: RootState) => state.auditHb.dimension

export const orderValue = (state: RootState) => state.auditHb.order

export const orderBidValue = (state: RootState) => state.auditHb.orderBid

export const sortByValue = (state: RootState) => state.auditHb.sortBy

export const sortByBidValue = (state: RootState) => state.auditHb.sortByBid

export const sortByErrorValue = (state: RootState) => state.auditHb.sortByError

export const sitesValue = (state: RootState) => state.auditHb.sites

export const bidderValue = (state: RootState) => state.auditHb.bidder

export const countriesValue = (state: RootState) => state.auditHb.countries

export const devicesValue = (state: RootState) => state.auditHb.devices

export const navigatorValue = (state: RootState) => state.auditHb.navigator

export const osValue = (state: RootState) => state.auditHb.os

export const dataPerfByBidderValue = (state: RootState) => state.auditHb.dataPerfByBidder

export const dataBidDetailValue = (state: RootState) => state.auditHb.dataBidDetailByBidder

export const dataGraphLineValue = (state: RootState) => state.auditHb.dataGraphLine

export const rowsPerPageValue = (state: RootState) => state.auditHb.rowsPerPage

export const rowsPerPageErrorValue = (state: RootState) => state.auditHb.rowsPerPageErrorCode

export const pageValue = (state: RootState) => state.auditHb.page

export const pageErrorValue = (state: RootState) => state.auditHb.pageError

export const dataErrorCodeValue = (state: RootState) => state.auditHb.dataErrorCode

export const orderErrorValue = (state: RootState) => state.auditHb.orderError

export const blocks = (state: RootState) => state.auditHb.blocks

export const filterGlobal = (state: RootState) => state.auditHb.filters

export const filterSites = (state: RootState) => state.auditHb.dataSites

export const filterBidder = (state: RootState) => state.auditHb.dataBidder

export const siteChecked = (state: RootState) => state.auditHb.siteSelected

export const refreshSite = (state: RootState) => state.auditHb.refreshSite

export const checkedCompanies = (state: RootState) => state.auditHb.selectedCompanies

export const checkedBidder = (state: RootState) => state.auditHb.selectedBidders

export const bidderChecked = (state: RootState) => state.auditHb.bidderSelected

export const refreshBidder = (state: RootState) => state.auditHb.refreshBidder

export const countrySelected = (state: RootState) => state.auditHb.countrySelected

export const navigaSelected = (state: RootState) => state.auditHb.navigatorSelected

export const deviceSelected = (state: RootState) => state.auditHb.deviceSelected

export const osSelected = (state: RootState) => state.auditHb.osSelected

export const loaderBlock1 = (state: RootState) => state.auditHb.statusPerfByBidder

export const loaderBlock2 = (state: RootState) => state.auditHb.statusBidDetailByBidder

export const loaderBlock3 = (state: RootState) => state.auditHb.statusErrorCode

export const countriesSelected = (state: RootState) => state.auditHb.selectedCountries

export const navigatorSelected = (state: RootState) => state.auditHb.selectedNavigator

export const devicesCheck = (state: RootState) => state.auditHb.selectedDevice

export const osCheck = (state: RootState) => state.auditHb.selectedOs

export const loadingAllBlocks = (state: RootState) => state.auditHb.statusBlock

export const refreshAll = (state: RootState) => state.auditHb.refreshGlobal

export default auditHbSlice.reducer

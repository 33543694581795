import { createAsyncThunk } from "@reduxjs/toolkit"
import { PlayersService } from "../Components/Players/Service/PlayersService"
import { RequestBodyPlayers } from "../Components/Players/Model/PlayersModel"
import { MesvideoService } from "../Mesvideo/services/MesvideoService"
import { VerticalContentService } from "../Components/Players/VerticalContent/Service/VerticalContentService"

const playersService = new PlayersService()
const videoService = new MesvideoService()
const verticalContent = new VerticalContentService()

interface body {
    id: string
    params: RequestBodyPlayers
}

export const getSites = createAsyncThunk('players/listSites', async (format: string) => {
    const sites = await playersService.getSitesWithParams(format)
    return sites
})

export const editSite = createAsyncThunk('players/update', async (params: body) => {
    const company = await playersService.editSite(params.id, params.params)
    if (company === null) {
        throw new Error("editSite returned null")
    }
    return company
})

export const deleteZone = createAsyncThunk('players/delete', async (id: string) => {
    const zone = await playersService.deleteZone(id)
    if (zone === null) {
        throw new Error("delete zone returned null")
    }
    return zone
})

export const getThematics = createAsyncThunk('players/thematics', async () => {
    const thematics = await videoService.getThematique()
    return thematics
})

export const getCatalogs = createAsyncThunk('players/catalogs', async () => {
    const catalogs = await videoService.getCatalogue()
    return catalogs
})

export const getPlaylists = createAsyncThunk('players/playlists', async () => {
    const catalogs = await videoService.getMyPlaylists({})
    return catalogs
})

export const getSyndication = createAsyncThunk('players/syndication', async () => {
    const response = await verticalContent.getSyndication()
    return response
})

export const getSyndicationByType = createAsyncThunk('players/syndicationByType', async (params: {id: string | number, type: string}) => {
    const response = await verticalContent.getSyndicationParams(params.id, params.type)
    return response
})

export const getSyndicationCatalogs= createAsyncThunk('players/syndicationCatalogs', async (id: string) => {
    const response = await verticalContent.getSyndicationCatalog(id)
    return response
})

export const getSyndicationPlaylist= createAsyncThunk('players/syndicationPlaylists', async (id: string) => {
    const response = await verticalContent.getSyndicationPlaylist(id)
    return response
})

export const updateSyndication= createAsyncThunk('players/syndicationUpdate', async (params: {type: string, id: number | string, idSite: string, subscribed: boolean}) => {
    const response = await verticalContent.updateSyndication(params.type, params.id, params.idSite, params.subscribed)
    return response
})
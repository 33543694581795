import { createAsyncThunk } from '@reduxjs/toolkit'
import { AuditTagService } from '../Components/AuditTag/AuditTagService/AuditTagService'
import { InputBlocs } from '../Components/AuditTag/Models/AuditTagModel';

const auditService = new AuditTagService()

export const getAllBlocks = createAsyncThunk(
    'audit/all',
    async (context: string) => {
        const dataPerfByBidder = await auditService.getParamBlocDashboard(context)
        return dataPerfByBidder
    }
)

export const getFiltersGlobal = createAsyncThunk(
    'audit/filters',
    async (context: string) => {
        const response = await auditService.getFilterFixe(context)
        return response
    }
)

export const getSiteBidder = createAsyncThunk(
    'audit/siteBidder',
    async (params: {bidder: string[], context: string}) => {
        const response = await auditService.FilterSiteByBidder(params.bidder, params.context)
        return response
    }
)

export const getBidder = createAsyncThunk(
    'audit/bidderSite',
    async (params: {sites: string[], context: string}) => {
        const response = await auditService.FilterBidderSites(params.sites, params.context)
        return response
    }
)

export const FiltreTablePerfByBidder = createAsyncThunk(
    'audit/filtrePerfByBidder',
    async (params: {id: number, from: string; to: string; dimension: string; filters: InputBlocs; context: string}) => {
        const {id, from, to, dimension, filters, context} = params
        const dataPerfByBidder = await auditService.FilterFirstBloc(id, from, to, dimension, filters, context)
        return dataPerfByBidder
    }
)
export const FilterBidDetailByBidder = createAsyncThunk(
    'audit/filterBidDetailByBidder',
    async (params: {id: number, from: string; to: string; dimension?: string; filters: InputBlocs; context: string}) => {
        const {id, from, to, filters, context} = params
        const dataBidDetailByBidder = await auditService.FilterSecondBlocAuditHB(id, from, to, filters, context)
        return dataBidDetailByBidder
    }
)
export const FilterBlocErrorHB = createAsyncThunk(
    'audit/filterBlocErrorHB',
    async (params: {id: number, from: string; to: string; filters: InputBlocs; context: string}) => {
        const {id, from, to, filters, context} = params
        const dataErrorCode = await auditService.FilterBlocErrorHB(id, from, to, filters, context)
        return dataErrorCode
    }
)

import { RootState } from '..'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PlaylistWithVideoData } from '../../Mesvideo/models/MesvideoModel'

interface Playlist {
    playlists: PlaylistWithVideoData[]
}

const initialState: Playlist = {
    playlists: []
}


export const playlistSlice = createSlice({
    name: 'playlist',
    initialState,
    reducers: {
        setPlaylists(state, action: PayloadAction<PlaylistWithVideoData[]>) {
            return { ...state, playlists: action.payload }
        },
        
    }
})

export const { setPlaylists } = playlistSlice.actions

export const listPlaylists = (state: RootState) => state.playlist.playlists


export default playlistSlice.reducer

import { DigitekaLogoBlack } from '../Images/DigitekaLogoBlack'

export const FallBackLoading = (props: { errorMessage?: string; showReload?: boolean }) => {
    return (
        <div
            style={{
                height: '100vh',
                width: '100vw',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <DigitekaLogoBlack style={{ width: '200px' }} />
            {props.errorMessage && <p style={{ color: '#999' }}>{props.errorMessage}</p>}
            {props.showReload && <button onClick={() => window.location.reload()}>Reload</button>}
        </div>
    )
}

import auditHbReducer from "./AuditHb/AuditHb"
import consumptionReducer  from "./consumption/consumption"
import downloadReducer from "./download-video/download"
import playersReducer from "./players/players"
import chapterReducer from "./video/chapter"
import playlistReducer from "./video/playlist"
import subtitleReducer from "./video/subtitle"
import brandContentReducer from "./BrandContent/BrandContent"
// eslint-disable-next-line
export default {
    consumption: consumptionReducer,
    players: playersReducer,
    subtitle: subtitleReducer,
    chapter: chapterReducer,
    playlist: playlistReducer,
    download: downloadReducer,
    auditHb: auditHbReducer,
    brandContent: brandContentReducer
}